import React from "react";
import { AboutOneData } from "@/data";
import serviceS2 from "@/images/s2.jpg";
const AboutOne = () => {
  const { sectionContent, gallery, counter } = AboutOneData;
  return (
    <section className="commonSection">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12">
          <div className="serviceArea">
              <h4 className="black-text">САНХҮҮГИЙН ТООЦООЛОХ ГРУПП ХХК</h4>
              <h4>Үйл ажиллагааны замнал</h4>
              <p>
              Анх 1997 онд санхүү, нягтлан бодох бүртгэлийн програм хангамжийн үйлдвэрлэл, техник хангамжийн засвар үйлчилгээний чиглэлээр  үйл ажиллагаагаа эхлүүлснээс хойш тасралтгүй 25 жилийн турш мэдээллийн технологийн салбарт өөрсдийн түүхийг бүтээсээр ирсэн хамт олон.
              </p>
              <div className="row gaping">
                <div className="col-lg-5 col-sm-12 col-md-5">
                  <img src={serviceS2} alt="" />
                </div>
                <div className="col-lg-7 col-sm-12 col-md-7">
                  <h4>Бүтээгдэхүүн хөгжүүлэлт</h4>
                  <ul>
                    <li>
                      <i className="fa fa-globe"></i>2022 он	“FISCUS” Онлайн нягтлан бодох бүртгэлийн програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2018 он	 “FISCUS TREASURE 1.0” Мөнгөн бүртгэлийн програм</li>
                    <li>
                      <i className="fa fa-globe"></i>2017 он	 “LANDPAY 1.0” Газрын төлбөр удирдлагын програм</li>
                    <li>
                      <i className="fa fa-globe"></i>2016 он	 “LEADAR MONITORING 3.0” Мониторинг удирдлагын програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2015 он	 “PHARMACY 1.0” Эмийн бүртгэлийн програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2015 он	 “PHARMACY 1.0” Эмийн бүртгэлийн програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2015 он	 “PHARMACY 1.0” Эмийн бүртгэлийн програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2013 он 	“LEADAR 3.0” Жижиг дунд аж ахуй нэгжийн нягтлан бодох бүртгэлийн програм <br />
                      “LEADAR MONITORING 3.0” Мониторинг удирдлагын програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2010 он	 "STUDENT 1.0" Оюутны төлбөр бүртгэлийн систем
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2009 он	 “LEADAR 2.0” Жижиг дунд аж ахуй нэгжийн нягтлан бодох бүртгэлийн програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2008 он	"ACOLOUS 2.0" Нягтлан бодох бүртгэлийн програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2007 он	 "ACOLOUS - MONTH" Сарын мэдээний програм<br />
                      “MONITORING” Мониторинг удирдлагын програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2006 он 	"LEADER 1.0" Жижиг дунд аж ахуй нэгжийн нягтлан бодох бүртгэлийн програм<br />
                      "PLASTIC 1.0" Төсөвт байгууллагын санхүүгийн тайлан нэгтгэлийн програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2005 он 	"ACOLOUS 1.0" Төсвийн байгууллагын нягтлан бодох бүртгэлийн програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2004 он 	"AFIS" УБ хотын архивын дижитал архив систем
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2003 он 	 "BUMIS" Төрийн сангийн нягтлан бодох бүртгэлийн програм<br />
                      "SCINSURANC 1.0" даатгалын програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>2002 он	"SCOFFICE 1.0" Нягтлан бодох бүртгэлийн программ
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>1999 он	“ACCOUNTING” Даатгалын нягтлан бодох бүртгэлийн програм
                    </li>
                    <li>
                      <i className="fa fa-globe"></i>1998 он	“SCSYSTEM 3.0” Тєсвийн тайлан нэгтгэл, нягтлан бодох бүртгэлийн програм. <br />
                      (Монгол улсын анхны нягтлан бодох бүртгэлийн програм) <br />
                    </li>
                    <br />
                    <li></li>
                  </ul>
                </div>
              </div>
              <h4>Бүтээгдэхүүний хэрэглээ</h4>
              <p>
              Өнөөдөр төсвийн болон хувийн хэвшлийн аж ахуйн нэгжүүд, яам, тамгын газар, агентлагууд тус компанийн програмын үйлчилгээг үйл ажиллагаандаа өргөнөөр хэрэглэж байгаа төдийгүй бид бүтээгдэхүүн үйлчилгээг өргөжүүлж  принтерийн хор, компьютерын дагалдах хэрэгсэл, вирусийн програмын худалдаа үйлчилгээг хэрэглэгч бүрт санал болгон хамтын ажиллагаагаа улам бүр өргөжүүлсээр байна.
              </p>
              <h4>Хүний нөөцийн чадавх</h4>
              <p>
              Тус компанийн 40 гаруй мэргэжилтнүүдээс бүрдсэн хүний нөөцийн мэргэшсэн  баг хамт олон 3000 гаруй харилцагчдад бүтээгдэхүүн үйлчилгээг үзүүлж байна.
              </p>
              <b>“Ажлыг тань хөнгөвчилж, ая тухыг хэрэглэгч танд хүргэнэ”</b> гэсэн алсын хараан дор бид хэрэглэгч бүрийн хүсэл шаардлагад нийцсэн бүтээгдэхүүн үйлчилгээг нэвтрүүлж, таны үнэт цагийг хэмнэн, түргэн шуурхай, найдвартай үйлчилгээг үзүүлэх болно.
            </div>
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </section>
  );
};

export default AboutOne;
